// extracted by mini-css-extract-plugin
export var root = "sample-module--root--f2608";
export var content = "sample-module--content--7c1c9";
export var gallery = "sample-module--gallery--78cf6";
export var details = "sample-module--details--5129c";
export var priceContainer = "sample-module--priceContainer--a9ff0";
export var vendor = "sample-module--vendor--5badb";
export var sizeContainer = "sample-module--sizeContainer--d3995";
export var quantityContainer = "sample-module--quantityContainer--c34b1";
export var actionContainer = "sample-module--actionContainer--10fb9";
export var addToButtonContainer = "sample-module--addToButtonContainer--56493";
export var wishlistActionContainer = "sample-module--wishlistActionContainer--17a67";
export var heartFillContainer = "sample-module--heartFillContainer--bf84b";
export var description = "sample-module--description--7608f";
export var accordionTitle = "sample-module--accordionTitle--1475e";
export var informationContainer = "sample-module--informationContainer--41fe4";
export var information = "sample-module--information--deb52";
export var suggestionContainer = "sample-module--suggestionContainer--b38bd";
export var attributeContainer = "sample-module--attributeContainer--eea90";
export var show = "sample-module--show--ee6af";
export var hide = "sample-module--hide--89e1d";